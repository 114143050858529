import Vue from 'vue'

import App from './App.vue'
import './logger'


//缓存localstorage
import Storage from 'vue-ls'
const storageOptions = {
  namespace: 'jyl_',
  name: 'ls',
  storage: 'local'
}
Vue.use(Storage,storageOptions)

import VueCookies from 'vue-cookies';

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

//事件总线
Vue.prototype.bus = new Vue();
Vue.config.productionTip = false

import router from './router'


import Store from './store';
Vue.prototype.store = Store;
//初始化
Store.init()

import './permission';

Vue.use(VueCookies);

//设备监听
import { deviceEnquire} from './util/device'
deviceEnquire(deviceType => {
  Store.state.deviceType = deviceType;
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
});


// import './testApi';




