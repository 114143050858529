<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods:{

  },
  mounted(){

  }
}
</script>

<style>

body{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-x: hidden;
  font-family: Microsoft YaHei,Helvetica,STHeiti STXihei,Microsoft JhengHei,Arial;
}

div {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

#app {
  height: 100vh;
}
</style>
