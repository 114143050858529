import Vue from 'vue'
import router from './router'
import Store from './store'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' 

NProgress.configure({
    showSpinner: false
})

const whiteList = ['home','login','not-found']

router.beforeEach((to,from,next) => {
    NProgress.start()
    
    Vue.$log.debug('login:',Store.state.isLogin)
    Vue.$log.debug("to : ",to)
    if(Store.state.isLogin){
        next()
        NProgress.done()
        return 
    }
    if(whiteList.includes(to.name)){
        next()
        NProgress.done()
        return 
    }
    next({
        name: 'login',
        query: {
            redirect: to.fullPath
        }
    })
    NProgress.done()
})
