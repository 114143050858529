import Vue from 'vue';
import { leftMenuItems } from "@/router";

const LOGIN_KEY = "user_login_state";
const USER_KEY = "user_info";

const Store = {
  debug: true,
  state:{
    activeName: null,
    deviceType: null,
    isLogin: -1,
    user: null,
    menus:[]
  },
  setActiveName(newValue){
    // if(this.debug) console.log("setActiveName: ",newValue);
    this.state.activeName = newValue;
  },
  clearUserLogin(){
    this.state.isLogin = false;
    Vue.ls.set(LOGIN_KEY,false);
    Vue.ls.remove(USER_KEY);
  },
  setUserLogin(user){
    this.state.isLogin = true
    this.state.user = user;
    Vue.ls.set(LOGIN_KEY,true);
    Vue.ls.set(USER_KEY,JSON.stringify(user));
    this.filterMenu();
  },
  filterMenu(){
    const user = this.state.user;
    if (user) {
      const userType = user.user_type;
      const hiddenMenu = user.hiddenMenu || [];
      // this.$log.debug("userType:" + userType);
      let newLeftMenuItems = JSON.parse(JSON.stringify(leftMenuItems));
      newLeftMenuItems.forEach((menu) => {
        menu.items = menu.items.filter((item) => {
          return item.meta.type.indexOf(userType) >= 0 && hiddenMenu.indexOf(item.name) < 0;
        });
        if (menu.items.length <= 0) {
          menu.notShow = true;
        }else {
          menu.notShow = false;
        }
      });
      // this.$log.debug(newLeftMenuItems);
      this.state.menus = newLeftMenuItems.filter((menu) => {
        return !menu.notShow;
      });
      // this.$log.debug(this.menus);
    }
  },
  init(){
    this.state.isLogin = Vue.ls.get(LOGIN_KEY,false);
    var user = Vue.ls.get(USER_KEY);
    if(user){
      this.state.user = JSON.parse(user);
    }
  }
};

export default Store;